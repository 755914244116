import React from "react"

export default function Main() {
    return (
        <main>
            <h1>Spartans, Gladiators and Guardians Standup</h1>
            <ul>
                <li>What was completed yesterday?</li>
                <li>What is plan for today?</li>
                <li>Any blockers?</li>
                <li>Any parking lot item?</li>
            </ul>
        </main>
    )
}
import React from "react"

export default function Navbar() {
    return (
        <nav>
            <img src="cb-logo.png" alt="The College Board"></img>
            <ul>
                <li><a href="https://jira.collegeboard.org/secure/RapidBoard.jspa?rapidView=75" target="_blank" rel="noopener noreferrer">
                Spartans Board </a></li>

                <li><a href="https://jira.collegeboard.org/secure/RapidBoard.jspa?rapidView=2137" target="_blank" rel="noopener noreferrer">
                Infra Board </a></li>

                <li><a href="https://jira.collegeboard.org/secure/RapidBoard.jspa?rapidView=1925" target="_blank" rel="noopener noreferrer">
                IEO/Atlas Board </a></li>

                <li><a href="https://confluence.collegeboard.org/display/CBEC/calendars" target="_blank" rel="noopener noreferrer">
                Event Calendar </a></li>

                <li><a href="https://collegeboard.pagerduty.com/reports#incidents?since=2022-10-10T00%3A00%3A00&until=2022-10-11T23%3A59%3A59&rollup=daily&time_zone=America%2FNew_York" target="_blank" rel="noopener noreferrer">
                Incidents (PagerDuty)</a></li>
            </ul>
        </nav>
    )
}
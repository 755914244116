import Navbar from './components/Navbar';
import Main from './components/Main';

export default function App() {
  return (
    <container>
      <Navbar />
      <Main />
    </container>
  );
}
